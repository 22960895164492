import React from 'react'
import ReactDOM from 'react-dom'
import { Provider} from 'react-redux';
import { store } from './redux/store';

import * as d3 from 'd3';

import {setActiveCategory, setCategories, setRecommender, setFilterBusiness, setColorScale} from './redux/action-creators'
import Map from './components/map'
import Menu from './components/menu'
import Legend from './components/legend'

import categories_csv from './pred/saopaulo-categories.csv'

// import PropTypes from "prop-types";

class Application extends React.Component {

  componentDidMount() {

      d3.csv(categories_csv).then(function(cat) {
          setCategories(cat);
      }).catch(function(err) {
          throw err;
      });

  }

    render() {
    return (
      <Provider store={store}>
        <div>
          <Map />
          <Menu onChangeCategory={setActiveCategory} onChangeRecommender={setRecommender} onChangeFilterBusiness={setFilterBusiness} onChangeColorScale={setColorScale}/>
          <Legend name="Recommendations"/>
        </div>
      </Provider>
    );
  }

}

ReactDOM.render(<Application />, document.getElementById('app'));
