import React from 'react';
import Select from 'react-select';
import ToggleGroup from '../lib/ToggleGroup';
import chroma from 'chroma-js';

import PropTypes from 'prop-types'

import { connect } from 'react-redux'


let Menu = class Menu extends React.Component {

  static propTypes = {
      categories: PropTypes.array.isRequired,
      onChangeRecommender: PropTypes.func.isRequired,
      onChangeCategory: PropTypes.func.isRequired,
      onChangeFilterBusiness: PropTypes.func.isRequired,
      onChangeColorScale: PropTypes.func.isRequired
  };

  render() {
    let { categories, onChangeCategory, onChangeFilterBusiness, onChangeColorScale, filterBusiness, activeCategory } = this.props;

      const colorItems = [
          { value: "green", label: <div> green </div>}, // <svg className="h18 w18"> <use xlinkHref="#icon-water"></use>  </svg>
          { value: "blue", label:  <div> blue  </div> },
          { value: "orange", label: <div> orange  </div>},
          { value: "red", label: <div> red </div>},
          { value: "purple", label: <div> purple </div>},
      ];

      //TODO: put into a json file and pass using props
      //TODO: update descriptions
      const items = [
          { id: 1, label: "R1", tooltip: "Recommender BPRMF5" },
          { id: 2, label: "R2", tooltip: "Recommender MP" },
          { id: 3, label: "R3", tooltip: "Recommender PACO" },
      ];

      categories = categories.map(c => { return {'label': c.category, 'value': c.id } });

      categories.sort((a,b) => a.label.localeCompare(b.label)) ;

      const Radio = ({ id, label, tooltip, checked, onChange }) => {
          return (
              <label className='toggle-container' htmlFor={id}>
                  <input type="radio" name="recommender" id={id} checked={checked} onChange={onChange} />
                  <div className='toggle tooltip py6'>
                      {label}
                      <div className="tooltip-text">
                          <div className='flex-parent-inline flex-parent--center-cross flex-parent--column'>
                              <div className='flex-child px6 py6 mt6 bg-darken75 color-white align-center  round txt-bold txt-s'>
                                  {tooltip}
                              </div>
                          </div>
                      </div>
                  </div>
              </label>
          );
      };

      const Checkbox = ({ id, label, onChange, checked, disabled }) => {
        return(
                <label className="relative  bg-white round  px6 py6 checkbox-container mr6  ml6">
                    <input checked={checked} disabled={disabled} type="checkbox" id={id} onChange={onChange}/>
                    <div className="checkbox mr6">
                        <svg className='icon'> <use xlinkHref='#icon-check'/> </svg>
                    </div>
                    <div >
                        {label}
                    </div>
                </label>
        );
      };

      const Mail = () => {
          return(
              <div className="relative col col--1 mt3">
                  <button className='tooltip btn btn--stroke bg-white'>
                      <svg className="icon h18 w18 mr3">
                          <use xlinkHref="#icon-mail"></use>
                      </svg>
                      <div className="tooltip-text">
                          <div className='flex-parent-inline flex-parent--center-cross flex-parent--column'>
                              <div className='flex-child px6 py6  mt6 bg-darken75 color-white align-center  round txt-bold txt-s'>
                                  Send us a comment
                              </div>
                          </div>
                      </div>
                  </button>
              </div>
          );
      };



      const renderRecommenders = (items) => {

        let { onChangeRecommender } = this.props;


        return (
            <div className="relative toggle-group  toggle-group--s bg-white round">
                <ToggleGroup defaultOn={[0]}>
                    {({ toggle, isOn }) => {
                        return items.map((item, index) => {
                            return (
                                <div key={index}>
                                    <Radio
                                        checked={isOn(index)}
                                        onChange={() => {onChangeRecommender(index); toggle(index)}}
                                        {...item}
                                    />
                                </div>
                            );
                        });
                    }}
                </ToggleGroup>
            </div>
        );
    };

      const dot = (color = '#ccc') => ({
          alignItems: 'center',
          display: 'flex',

          ':before': {
              backgroundColor: color,
              borderRadius: 10,
              content: '" "',
              display: 'block',
              marginRight: 8,
              height: 10,
              width: 10,
          },
      });

      const colourStyles = {
          control: styles => ({ ...styles, backgroundColor: 'white' }),
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
              const color = chroma(data.color);
              return {
                  ...styles,
                  backgroundColor: isDisabled
                      ? null
                      : isSelected
                          ? data.color
                          : isFocused
                              ? color.alpha(0.1).css()
                              : null,
                  color: isDisabled
                      ? '#ccc'
                      : isSelected
                          ? chroma.contrast(color, 'white') > 2
                              ? 'white'
                              : 'black'
                          : data.color,
                  cursor: isDisabled ? 'not-allowed' : 'default',

                  ':active': {
                      ...styles[':active'],
                      backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
                  },
              };
          },
          input: styles => ({ ...styles, ...dot() }),
          placeholder: styles => ({ ...styles, ...dot() }),
          singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
      };



    return (
    <nav className="grid bg-white round px12 py12">
        {renderRecommenders(items)}
        <Select className="relative col col--3 ml12 mr12" placeholder="Select category..." options={categories} onChange={onChangeCategory} />
        <Checkbox id="cb-show-business"
                  className="relative"
                  checked={filterBusiness}
                  disabled={!(activeCategory && activeCategory.value)}
                  onChange={(e) => {onChangeFilterBusiness(e.target.checked)}}
                  label="Only business from selected category"/>
        <Select className="relative col col--2 mr6 ml6" placeholder="Districts color..."
                defaultValue={colorItems[0]}
                options={colorItems} onChange={(e) => onChangeColorScale(e.value) }/>
        <Mail />
      </nav>
    );
  }
}

function mapStateToProps(state) {
  return {
      categories: state.categories,
      recommender: state.recommender,
      filterBusiness: state.filter_business,
      activeCategory: state.activeCategory
  };
}

Menu = connect(mapStateToProps)(Menu);

export default Menu;

