import { store } from './store'
import * as Constants from '../constants'

export function setActiveCategory(activeCategory) {
  store.dispatch({
    type: Constants.SET_ACTIVE_CATEGORY,
    activeCategory: activeCategory
  });
}


export function setRecommender(recommender) {
  store.dispatch({
    type: Constants.SET_RECOMMENDER,
    recommender
  });
}


export function setFilterBusiness(filter_business) {
  store.dispatch({
    type: Constants.SET_FILTER_BUSINESS,
    filter_business
  });
}


export function setCategories(categories) {
  store.dispatch({
    type: Constants.SET_CATEGORIES,
    categories
  });
}


export function setColorScale(scoreScale) {
  store.dispatch({
    type: Constants.SET_SCORE_COLOR_SCALE,
    scoreScale
  });
}

