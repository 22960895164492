import * as Constants from '../constants'
import * as d3 from 'd3';


const categories = [];
const activeCategory = {};
const recommender = 'bprmf5';
const filter_business = false;
const scoreScale = d3.schemeGreens;


const initialState: State = {
  categories,
  activeCategory,
  filter_business,
  recommender,
  scoreScale
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case Constants.SET_ACTIVE_CATEGORY:
      return Object.assign({}, state, {
        activeCategory: action.activeCategory
      });
    case Constants.SET_RECOMMENDER:
      let recommender = 'bprmf5';
      if(action.recommender === 1)
        recommender = 'mp';
      else if( action.recommender ===2)
        recommender = 'paco';
      return Object.assign({}, state, {
        recommender: recommender
      });
    case Constants.SET_FILTER_BUSINESS:
      return Object.assign({}, state, {
        filter_business: action.filter_business
      });
    case Constants.SET_SCORE_COLOR_SCALE:
      let scoreScaleColor = d3.schemeGreens;
      if(action.scoreScale === 'green')
        scoreScaleColor = d3.schemeGreens;
      else if( action.scoreScale ==='blue')
        scoreScaleColor = d3.schemeBlues;
      else if( action.scoreScale ==='orange')
        scoreScaleColor = d3.schemeOranges;
      else if( action.scoreScale ==='red')
        scoreScaleColor = d3.schemeReds;
      else if( action.scoreScale ==='purple')
        scoreScaleColor = d3.schemePurples;
      return Object.assign({}, state, {
        scoreScale: scoreScaleColor
      });
    case Constants.SET_CATEGORIES:
      return Object.assign({}, state, {
        categories: action.categories
      });
    default:
      return state;
  }
}

export { reducer, initialState };
