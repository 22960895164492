
function identity(x) { return x; }

function noop() {}


function groupReduce(values, keyof = identity, reduce, init = noop) {
    const map = new Map();
    let index = -1;
    for (const value of values) {
        const key = keyof(value, ++index, values);
        map.set(key, reduce(map.has(key) ? map.get(key) : init(key), value, index, values));
    }
    return map;
}


const reduce = (p, v) => {return (p.push(v), p);};
const init = () => [];
function group(values, keyof) {
    return groupReduce(values, keyof, reduce, init);
};

export default group;
