import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

let Legend = class Legend extends React.Component {

  static propTypes = {
    scoreScale: PropTypes.array.isRequired
  };

  render() {
    const { name, description, scoreScale, activeCategory } = this.props;


    const stops = scoreScale[5].slice().reverse();
    const descriptionStops = ['Best', '', '', '', 'Least'];

    const renderLegendKeys = (stop, i) => {
      return (
        <div key={i} className='txt-s'>
          <span className='mr6 round-full w12 h12 inline-block align-middle' style={{ backgroundColor: stop }} />
          <span>{descriptionStops[i]}</span>
        </div>
      );
    };//`${stop[0].toLocaleString()}`

      if (activeCategory && activeCategory.value){
        return (
          <div className="bg-white absolute bottom right mr12 mb24 py12 px12 shadow-darken10 round z1 wmax180">
            <div className='mb6'>
              <h2 className="txt-bold txt-s block">{name}</h2>
              <p className='txt-s color-gray'>{description}</p>
            </div>
            {stops.map(renderLegendKeys)}
          </div>
        );
      }else{
          return(<span/>);
      }
  }
}

function mapStateToProps(state) {
  return {
      scoreScale: state.scoreScale,
      activeCategory: state.activeCategory
  };
}

Legend = connect(mapStateToProps)(Legend);

export default Legend;
