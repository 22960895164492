export const SET_ACTIVE_CATEGORY = 'SET_ACTIVE_CATEGORY';
export const SET_RECOMMENDER = 'SET_RECOMMENDER';
export const SET_FILTER_BUSINESS = 'SET_FILTER_BUSINESS';

export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_SCORE_COLOR_SCALE = 'SET_SCORE_COLOR_SCALE';




