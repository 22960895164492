import React from 'react'
import PropTypes from 'prop-types'

import mapboxgl from 'mapbox-gl'
import { connect } from 'react-redux'

import * as d3 from 'd3';
import group from "../lib/group";
import concaveHull from "../lib/concaveHull";

// import prefeituras_geometry from '../sp_limites_admin/SIRGAS_SHP_distrito_polygon'


mapboxgl.accessToken = 'pk.eyJ1IjoicGFveW8xIiwiYSI6ImE1Mm9ETmcifQ.FujqVn9PuHc35OWRnWCaUw';

let Map = class Map extends React.Component {
  map;

  static propTypes = {
    activeCategory: PropTypes.object.isRequired,
    filter_business: PropTypes.bool.isRequired,
    recommender: PropTypes.string.isRequired,
    scoreScale: PropTypes.array.isRequired
  };

  constructor(){
    super();
    this.svg = null;
    this.path_distr = null;
    this.path_business = null;
    this.businessRegions = null;
    this.business = null;
    this.recommendations = null;

    // this.predDir = "../pred/";
    // this.predDir2 = "../pred-2/";

    this.zoomScale = d3.scaleQuantize()
        .domain([10, 13.5])
        .range(d3.range(100,501,100));

    this.currZoom = 10.5;
    this.currScale = this.zoomScale(this.currZoom);



    }

  project (map) {
    return d3.geoTransform({
      point: function(lon, lat) {
        const point = map.project(new mapboxgl.LngLat(lon, lat));
        this.stream.point(point.x, point.y);
      }
    });
  }


  createBusiness(data){

    this.business = {
      "type": "FeatureCollection",
      "crs":  {
        "type": "name",
        "properties":  {"name": "business"}
      },
      "features":[]
    };

    data.forEach(d=> {
        const businessFeature = {
            "type": "Feature",
            "geometry":{
            "type":"Point",
                "coordinates": [+d.longitude, +d.latitude]
            },
            "properties": {
                "Name": d.name,
                "region-id": +d.cluster_id,
                "categories": d.categories.split(",")
            }
        };

        d.categories.split(",").forEach(c => {
            businessFeature.properties[c] = true;
        });

      this.business.features.push(businessFeature);
    });

  }

  addBusinessToMap(){

    if(this.map.getSource('business')){

      this.map.getSource('business')
          .setData(this.business);

    }else{

      this.map.addSource('business', {
        type: 'geojson',
        data: this.business
      });

      this.map.addLayer({
        id: 'business-heat',
        type: 'heatmap',
        source: 'business',
        maxzoom: 15,
        paint: {
          // increase weight as diameter breast height increases
          'heatmap-weight': {
            property: 'dbh',
            type: 'exponential',
            stops: [
              [1, 0],
              [62, 1]
            ]
          },
          // increase intensity as zoom level increases
          'heatmap-intensity': {
            stops: [
              [11, 1],
              [15, 3]
            ]
          },
          // assign color values be applied to points depending on their density
          'heatmap-color': [
            'interpolate',
            ['linear'],
            ['heatmap-density'],
            0, 'rgba(236,222,239,0)',
            0.2, 'rgb(208,209,230)',
            0.4, 'rgb(166,189,219)',
            0.8, 'rgb(103,169,207)'
            // 0.8, 'rgb(28,144,153)'
          ],
          // increase radius as zoom increases
          'heatmap-radius': {
            stops: [
              [11, 15],
              [15, 20]
            ]
          },
          // decrease opacity to transition into the circle layer
          'heatmap-opacity': {
            default: 1,
            stops: [
              [14, 0.5],
              [15, 0]
            ]
          },
        }
      }, 'waterway-label');

      this.map.addLayer({
        id: 'business-point',
        type: 'circle',
        source: 'business',
        minzoom: 12.75,
        paint: {
          // increase the radius of the circle as the zoom level and dbh value increases
          'circle-radius': {
            property: 'dbh',
            type: 'exponential',
            stops: [
              [{ zoom: 15, value: 1 }, 5],
              [{ zoom: 15, value: 62 }, 10],
              [{ zoom: 22, value: 1 }, 20],
              [{ zoom: 22, value: 62 }, 50],
            ]
          },
          'circle-color': {
            property: 'dbh',
            type: 'exponential',
            stops: [
              [0, 'rgba(236,222,239,0)'],
              [2, 'rgb(236,222,239)'],
              [4, 'rgb(208,209,230)'],
              [6, 'rgb(166,189,219)'],
              [8, 'rgb(103,169,207)']
            ]
          },
          'circle-stroke-color': 'white',
          'circle-stroke-width': 1,
          'circle-opacity': {
            stops: [
              [14, 0],
              [15, 1]
            ]
          }
        }
      }, 'waterway-label');
    }
  }

  createBusinessRegions(data){
    const cluster_groups = Array.from(
        group(data, d => d.cluster_id),
        ([name, data]) => [name, data.map(d => [+d.longitude, +d.latitude, +d.id])]
    );

    const hull = concaveHull().padding(.001)
        .distance(0.05);

    const cluster_hulls = cluster_groups.map(c => [c[0], hull(c[1].map(d => [d[0], d[1]]))]);

    const cluster_hulls_flatten = cluster_hulls.map(d => d[1]).flat(1);
    const cluster_ids_flatten = cluster_hulls.map(d => d[1].map(c => d[0])).flat(1)

    this.businessRegions = {
      "type": "FeatureCollection",
      "crs": {
        "type": "name",
        "properties": {"name": "hulls"}
      },
      "features": []
    };



    cluster_hulls_flatten.forEach((ch, i) => {
      this.businessRegions.features.push(
          {
            "type": "Feature",
            "geometry": {
              "type": "Polygon",
              "coordinates": [ch]
            },
            "properties": {
              "id": cluster_ids_flatten[i],
              "score": -1
            }
          }
      );
    });
  }


  loadBusinessRegions(){

    let this_ = this;

    d3.csv(require("../pred/" + this.currScale + "/SaoPaulo/kmeans/SaoPaulo_kmeans_202_" + this.currScale + ".csv")).then(function(data) {

      // console.log(data);

      data.filter(d => !Number.isNaN(+d.latitude))


      // if(this_.business == null){

        this_.createBusiness(data);
        this_.addBusinessToMap();

      // }

      this_.createBusinessRegions(data);
      this_.addBusinessRegionsToMap();


      // this_.renderRegions(this_);
      this_.loadRecommendedRegions();



    }).catch(function(err) {
      throw err;
    });


  }

  addBusinessRegionsToMap(){

    if(this.map.getSource('businessRegions')){
      this.map.getSource('businessRegions')
          .setData(this.businessRegions);

    }else{
      this.map.addSource('businessRegions', {
        type: 'geojson',
        data: this.businessRegions
      });


      this.map.addLayer({
        id: 'business-regions',
        type: "fill",
        source: "businessRegions",
        paint: {
          'fill-color': this.scoreStops[1][1],
          'fill-opacity': 0.5,
          'fill-outline-color': 'black'
        }}, 'waterway-label');
    }
  }


  updateVisibleRegions(){

    let { activeCategory, filter_business, scoreScale } = this.props;

    if (activeCategory && activeCategory.value &&
        this.recommendations && this.recommendations[activeCategory.value]) {

      const scoreExtent = d3.extent(this.recommendations[activeCategory.value].filter(d => d.score > 0),
              d=>d.score);

      d3.range(scoreExtent[0], scoreExtent[1], (scoreExtent[1]-scoreExtent[0])/5).forEach((x,i) => {
            this.scoreStops[i][0] = x;
            this.scoreStops[i][1] = scoreScale[5][i];
          }
      );

      let recommendation_map = new Map();


      this.recommendations[activeCategory.value].forEach(x => { recommendation_map[x.region_id] =  x; });

      // For b values, insert them if missing, otherwise, update existing values
      this.businessRegions.features.forEach(x => {
        const existing = recommendation_map[x.properties.id];
        if (existing !== undefined){
          x.properties.score = existing.score;
        }
        else {
          x.properties.score = -10;
        }

      });

      //
      // let recommendation_region_ids = this_.recommendations[activeCategory.value]
      //     .filter(x => x.score > 0)
      //     .map(x => x.region_id);

      this.map.getSource('businessRegions')
          .setData(this.businessRegions);

      this.map.setLayoutProperty('business-regions', 'visibility', 'visible');

      this.map.setPaintProperty('business-regions', 'fill-color', {
        property: 'score',
        stops: this.scoreStops
      });

      this.map.setFilter('business-regions', ['>', 'score', 0]);


      if(filter_business) {
          // this.map.setFilter('business-heat', ['in', 'region-id', ...recommendation_region_ids]);
          // this.map.setFilter('business-point', ['in', 'region-id', ...recommendation_region_ids]);
          this.map.setFilter('business-heat', ['has', activeCategory.label]);
          this.map.setFilter('business-point', ['has', activeCategory.label]);
      }else{
          this.map.setFilter('business-heat');
          this.map.setFilter('business-point');
      }

      // data_r = this_.businessRegions.features.filter(r => recommendation_set.has(r.properties.id)); // prediction!! no properties id that'' the cluster id
      }else{
        this.scoreStops = scoreScale[5].map(c => [-1, c]);

        this.map.setFilter('business-heat');
        this.map.setFilter('business-point');

        this.map.setFilter('business-regions');
        this.map.setPaintProperty('business-regions', 'fill-color', this.scoreStops[1][1]);
        this.map.setLayoutProperty('business-regions', 'visibility', 'none');
    }

  }

  loadRecommendedRegions() {

    let { recommender } = this.props;

    let this_ = this;

    console.log(recommender);


    d3.text(require( "../pred-2/" + this_.currScale + "/SaoPaulo/kmeans/" + recommender + "/all.pred")).then(text => {


      this_.recommendations = {};


      text.split("\n").forEach((row, ci) => {


        row = row.split(" ");

        this_.recommendations[ci] = [];

        row.forEach((s, bj) => {
          this_.recommendations[ci].push({
            region_id: bj,
            score: +s
          });
        });

      });

      this.updateVisibleRegions();


      // console.log(this_.recommendations);
      // this_.renderRegions(this_);


    }).catch(function (err) {
      console.log("test");
      throw err;
    });

  }

  // loadSPLimits(){
  //   const prefeiturasData = {
  //     "type": "FeatureCollection",
  //     "features": [{
  //       "type": "Feature", // single feature
  //       "properties": {},
  //       "geometry": prefeituras_geometry
  //     }]
  //   };
  //
  //   this.map.addSource('prefeituras', {
  //     type: 'geojson',
  //     data: prefeituras_geometry
  //   });
  //
  //   console.log(prefeiturasData);
  //
  //
  //   this.map.addLayer({
  //     'id': 'prefeiturasLayer',
  //     "type": "line",
  //     'source': 'prefeituras',
  //     "paint": {
  //       "line-color": "#881623",
  //       // "fill-opacity": 1
  //     }
  //   }, 'waterway-label');
  // }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let { recommender } = this.props;

    if(prevProps.recommender !== recommender){
      this.loadRecommendedRegions();
    }else{
     // this.renderRegions(this);

      this.updateVisibleRegions();
    }

  }

  componentDidMount() {

    let { scoreScale } = this.props;
    let this_ = this;


    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: "mapbox://styles/mapbox/light-v10",
      center: [-46.63611, -23.5475],
      zoom: this.currZoom
    });

    this.scoreStops = scoreScale[5].map(c => [-1, c]);


    this.map.on('load', () => {
      this.loadBusinessRegions();
      // this.loadSPLimits();
    });

    this.map.on('zoom', () => {

      this.currZoom = this.map.getZoom();

      let newScale = this.zoomScale(this.currZoom);

      if(this.currScale !== newScale){
        this.currScale = newScale;
        console.log(this.currZoom, this.currScale, this.recommender);

        this.loadBusinessRegions();

      }

    });


    this.map.on('click', 'business-point', function(e) {
      new mapboxgl.Popup()
          .setLngLat(e.features[0].geometry.coordinates)
          .setHTML(e.features[0].properties.Name + "<br>" +
              e.features[0].properties.categories)
          .addTo(this_.map);
    });

  }


  render() {
    return (
        <div ref={el => this.mapContainer = el} className="absolute top right left bottom" />
    );
  }
}

function mapStateToProps(state) {
  return {
    activeCategory: state.activeCategory,
    filter_business: state.filter_business,
    recommender: state.recommender,
    scoreScale: state.scoreScale
  };
}

Map = connect(mapStateToProps)(Map);

export default Map;